import Vue from 'vue'
import Vuex from 'vuex'
import { Dialog } from 'vant';

import createPersistedState from "vuex-persistedstate";
import { getInfo } from '@/api'
import i18n from '../lang'



Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 'ru-ru',
    languageName: 'Русский', 
    isMenuActive: 1,
    defaultIndex: 0,
    userInfo: '',
    token: '',
    isLogin: true,
    isShow: false,
    phone_code: null,
    tan: true
  },
  getters: {
  },
  mutations: {
    changeLanguage(state, data) {
      state.language = data.value;
      state.languageName = data.name;
    },
    changeisMenuActive(state, value) {
      state.isMenuActive = value;
    },
    setUserInfo(state, value) { //用户信息
      state.userInfo = value;
    },
    setToken(state, value) { //token
      state.token = value;
    },
    changeIsLogin(state, value) {
      state.isLogin = value;
    },
    changeIsShow(state, value) {
      state.isShow = value;
    },
    changeDefaultIndex(state, value) {
      state.defaultIndex = value;
    },
    changePhoneCode(state, value) {
      state.phone_code = value;
    },
    changeTan(state, value) {
      state.tan = value;
    },
  },
  actions: {
    // 获取用户信息
    getInfo(context, params) {
      return new Promise((resolve, reject) => {
        getInfo({
          token: context.state.token,
        }, params).then(res => {
          if (res.code == 1) {
            context.commit('setUserInfo', res.data);
            if (res.data.tan_info && context.state.tan) {
              Dialog.alert({
                title: i18n.tc("home.notification"),
                confirmButtonText: i18n.tc("personalinformation.Confirm"),
                message: res.data.tan_info,
              }).then(() => {
                context.commit('changeTan', false);
              });
            }
            resolve(res.data);
          }
        })
      })
    },
  },
  modules: {
  },
  /* vuex数据持久化配置 */
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies  默认: localStorage
      storage: window.localStorage,
      // 存储的 key 的key值
      key: "store",
      render(state) {
        // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
        return { ...state };
      }
    })
  ]
})
