import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import HomeView from '../views/Home.vue'
import logo from '../views/logo/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'logo',
    component: logo
  },
  {
    path: '/sign',
    // path: '/',
    name: 'sign',
    component: () => import('../views/sign/index.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    redirect: {
      name: 'Home'
    },
    children: [
      {
        path: '/Home',
        name: 'Home',
        component: () => import('../views/Home/index.vue'),
        meta: {
          isMenuActive: 1
        }
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('../views/order/index.vue'),
        meta: {
          isMenuActive: 2
        }
      },
      {
        path: '/transaction',
        name: 'transaction',
        component: () => import('../views/transaction/index.vue'),
        meta: {
          isMenuActive: 3
        }
      },
      {
        path: '/distribution',
        name: 'distribution',
        component: () => import('../views/distribution/index.vue'),
        meta: {
          isMenuActive: 4
        }
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/index.vue'),
        meta: {
          isMenuActive: 5
        }
      }
    ]
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('../views/help/index.vue')
  },
  {
    path: '/Withdrawal',
    name: 'Withdrawal',
    component: () => import('../views/Withdrawal/index.vue')
  },
  {
    path: '/Recharge',
    name: 'Recharge',
    component: () => import('../views/Recharge/index.vue')
  },
  {
    path: '/Invitation',
    name: 'Invitation',
    component: () => import('../views/Invitation/index.vue')
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: () => import('../views/userInfo/index.vue')
  },
  {
    path: '/fundDetails',
    name: 'fundDetails',
    component: () => import('../views/fundDetails/index.vue')
  },
  {
    path: '/language',
    name: 'language',
    component: () => import('../views/language/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about/index.vue')
  },
  {
    path: '/selectPay',
    name: 'selectPay',
    component: () => import('../views/selectPay/index.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 全局前置守卫
router.beforeEach((to, from, next) => {
  // console.log(to.path);
  let token = store.state.token
  if (to.path === '/'||to.path === '/sign') {
    store.commit('setUserInfo', '');
    store.commit('setToken', '');
    next()
  } else if (to.path === '/language') {
    next()
  } else {
    if (token) {
      // 菜单高亮
      if (to.meta.isMenuActive) {
        store.commit('changeisMenuActive', to.meta.isMenuActive)
      }
      next()
    } else {
      next('/')
    }
  }

})


export default router
